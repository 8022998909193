// Generated by Framer (c7f0859)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/gz86A1Laf";

const enabledGestures = {FfO4Dlzex: {hover: true}, zGmc_kRNa: {hover: true}};

const cycleOrder = ["FfO4Dlzex", "zGmc_kRNa"];

const serializationHash = "framer-QSvKf"

const variantClassNames = {FfO4Dlzex: "framer-v-15a97e3", zGmc_kRNa: "framer-v-ysdyyw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Current: "zGmc_kRNa", Default: "FfO4Dlzex"}

const getProps = ({height, id, link, tap, title, width, ...props}) => { return {...props, d_k4Egym9: link ?? props.d_k4Egym9, f9jDtqiwB: title ?? props.f9jDtqiwB ?? "Link", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FfO4Dlzex", wFOHuwUdF: tap ?? props.wFOHuwUdF} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, f9jDtqiwB, d_k4Egym9, wFOHuwUdF, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FfO4Dlzex", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1865a23 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (wFOHuwUdF) {const res = await wFOHuwUdF(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={d_k4Egym9} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-15a97e3", className, classNames)} framer-lhgf60`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"FfO4Dlzex"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1865a23} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(37, 41, 45, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"FfO4Dlzex-hover": {backgroundColor: "var(--token-a5df8b6b-54d5-4388-af79-6b45e29e509b, rgb(38, 41, 44))"}, "zGmc_kRNa-hover": {backgroundColor: "var(--token-a5df8b6b-54d5-4388-af79-6b45e29e509b, rgb(48, 51, 54))"}}} {...addPropertyOverrides({"FfO4Dlzex-hover": {"data-framer-name": undefined}, "zGmc_kRNa-hover": {"data-framer-name": undefined}, zGmc_kRNa: {"data-framer-name": "Current"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-gxb8lp"} data-styles-preset={"gz86A1Laf"}>Link</motion.p></React.Fragment>} className={"framer-1ndyy4g"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"brbkMnVt6"} style={{opacity: 0.7}} text={f9jDtqiwB} variants={{"FfO4Dlzex-hover": {opacity: 1}, "zGmc_kRNa-hover": {opacity: 1}, zGmc_kRNa: {opacity: 1}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QSvKf.framer-lhgf60, .framer-QSvKf .framer-lhgf60 { display: block; }", ".framer-QSvKf.framer-15a97e3 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 12px 16px 12px 16px; position: relative; text-decoration: none; width: min-content; }", ".framer-QSvKf .framer-1ndyy4g { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QSvKf.framer-15a97e3 { gap: 0px; } .framer-QSvKf.framer-15a97e3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QSvKf.framer-15a97e3 > :first-child { margin-left: 0px; } .framer-QSvKf.framer-15a97e3 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 68
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"zGmc_kRNa":{"layout":["auto","auto"]},"FuXy8itJS":{"layout":["auto","auto"]},"zjfCSQoji":{"layout":["auto","auto"]}}}
 * @framerVariables {"f9jDtqiwB":"title","d_k4Egym9":"link","wFOHuwUdF":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerST_0ufkQ6: React.ComponentType<Props> = withCSS(Component, css, "framer-QSvKf") as typeof Component;
export default FramerST_0ufkQ6;

FramerST_0ufkQ6.displayName = "Nav Link";

FramerST_0ufkQ6.defaultProps = {height: 48, width: 68};

addPropertyControls(FramerST_0ufkQ6, {variant: {options: ["FfO4Dlzex", "zGmc_kRNa"], optionTitles: ["Default", "Current"], title: "Variant", type: ControlType.Enum}, f9jDtqiwB: {defaultValue: "Link", displayTextArea: false, title: "Title", type: ControlType.String}, d_k4Egym9: {title: "Link", type: ControlType.Link}, wFOHuwUdF: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerST_0ufkQ6, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})